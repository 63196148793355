<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="整单显示" name="first">
        <SupplierForm
          :form-option="formOption"
          :active-name="activeName"
          @stock-location-search="stockLocationSearchForm"
          @clear-form="clearForm"
        />
        <el-table
          v-loading="supplierTableLoading"
          :data="supplierTable"
          style="width: 100%"
          :header-cell-style="{background:'#fafafa'}"
        >
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="offerCode" label="报价单号" width="170" align="center" sortable>
            <template slot-scope="scope">
              <router-link
                :to="{ path: '/third-party/supplier-quote/view-quote', query: { id: scope.row.id,disabled:true }}"
                style="color:#1890ff;"
              >{{ scope.row.offerCode }}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="groupStyle" label="STYLE" align="center" sortable />
          <el-table-column prop="vendorName" label="供应商" align="center" sortable />
          <el-table-column prop="currencyType" label="币种" align="center" sortable>
            <template slot-scope="scope">
              {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
            </template>
          </el-table-column>
          <el-table-column prop="offerStatusI18" label="状态" align="center" sortable />
          <el-table-column prop="createByName" label="报价人" align="center" sortable />

          <el-table-column prop="createTime" label="报价时间" align="center" sortable width="103" />
          <el-table-column prop="remark" label="备注" align="center" sortable />
          <el-table-column fixed="right" :label="$t('page.operate')" width="90" align="center" sortable>
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.offerStatus === 0"
                type="text"
                size="small"
                @click="handelSupplier(scope.row.id,true)"
              >修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :pager="pager"
          :page-sizes="[10, 50, 200, 500]"
          @pagination="stockLocationSearch"
        />
      </el-tab-pane>
      <el-tab-pane label="明细显示" name="second">
        <SupplierForm
          :form-option="formDetailOption"
          :active-name="activeName"
          @stock-location-search="stockDetailSearchForm"
          @clear-form="clearForm"
        />
        <el-table
          ref="stockDetailTableRef"
          v-loading="supplierDetailTableLoading"
          class="mb-3"
          max-height="550px"
          :data="supplierDetailTable"
          :header-cell-style="{background:'#fafafa'}"
        >
          <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="offerCode" label="报价单号" width="165" align="center" sortable>
            <template slot-scope="scope">
              <router-link
                :to="{ path: '/third-party/supplier-quote/view-quote', query: { id: scope.row.vendorOfferId,disabled:true }}"
                style="color:#1890ff;"
              >{{ scope.row.offerCode }}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="productDevelopCode" label="产品开发编号" width="140" align="center" sortable />
          <el-table-column prop="offerStatusI18" label="状态" width="140" align="center" sortable />
          <el-table-column prop="vendorName" label="供应商" width="140" align="center" sortable />
          <el-table-column prop="imageUrl" align="center" sortable label="图片" width="160">
            <template slot-scope="scope">
              <img :src="scope.row.imageUrl" alt="暂无图片" style="width: 50px;height: 50px">
            </template>
          </el-table-column>
          <el-table-column
            prop="style"
            label="规格型号"
            width="260"
            align="center"
            :sortable="true"
            :sort-by="['style', 'color', 'sizeSort']"
          >
            <template slot-scope="scope">
              {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
            </template>
          </el-table-column>
          <el-table-column prop="currencyType" label="币种" width="120" align="center" sortable>
            <template slot-scope="scope">
              {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
            </template>
          </el-table-column>
          <el-table-column prop="isFlock" label="大底是否植绒" width="150" align="center" sortable />
          <el-table-column prop="packMethod" label="包装方式" width="120" align="center" sortable />
          <el-table-column prop="offerPrice" label="单价" width="120" align="center" sortable />
          <el-table-column prop="isExcludingTax" label="是否含税" width="120" align="center" sortable>
            <template slot-scope="scope">
              <p v-if="scope.row.isExcludingTax == 0">是</p>
              <p v-if="scope.row.isExcludingTax == 1">否</p>
            </template>
          </el-table-column>
          <el-table-column prop="effectiveStartDate" label="生效开始日期" width="150" align="center" sortable />
          <el-table-column prop="effectiveEndDate" label="生效结束日期" width="150" align="center" sortable />
          <el-table-column fixed="right" :label="$t('page.operate')" width="90" align="center" sortable>
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.offerStatus === 0"
                type="text"
                size="small"
                @click="handelSupplierDetail(scope.row.vendorOfferId,true)"
              >修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page-sizes="[10, 50, 200, 500]"
          :pager="detailPager"
          @pagination="stockDetailSearch"
        />

      </el-tab-pane>

    </el-tabs>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SupplierForm from '@/views/third-party/component/supplier-form'
import { getVendorOffer, getVendorOfferDetail } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {

  components: {
    Pagination,
    SupplierForm
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      dialogVisible: false,
      activeName: 'first',
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      detailPager: {
        size: 10,
        current: 1,
        total: 0
      },
      formOption: {
        showRow: false,
        isDetail: false,
        supplierform: {
          currencyType: '',
          style: '',
          offerUser: '',
          offerCode: '',
          offerStatus: '',
          vendorId: '',
          joinDate: []
        }
      },
      supplierTableLoading: false,
      supplierDetailTableLoading: false,
      supplierTable: [],
      supplierDetailTable: [],
      totalData: {}

    }
  },
  computed: {
    formDetailOption() {
      return Object.assign({}, this.formOption, { isDetail: true })
    }
  },
  mounted() {
    this.stockLocationSearch()
  },
  methods: {
    queryParameter(pager, params) {
      const [offerStartTime, offerEndTime] = this.formOption.supplierform.joinDate || []

      return Object.assign({ current: pager.current, size: pager.size }, params, { offerStartTime, offerEndTime })
    },
    handleClick(tab, event) {
      switch (tab.name) {
        case 'first':
          Object.assign(this.formOption.supplierform, this.$options.data.call(this).formOption.supplierform)
          if (!this.supplierTable.length) this._getVendorOffer(this.queryParameter(this.pager, this.formOption.supplierform))
          this.stockLocationSearch()
          break
        case 'second':
          Object.assign(this.formOption.supplierform, this.$options.data.call(this).formOption.supplierform, { isDetail: true })
          if (!this.supplierDetailTable.length) this._getVendorOfferDetail(this.queryParameter(this.detailPager, this.formOption.supplierform))
          this.stockDetailSearch()
      }

      this.$emit('updateInfo', this.activeName)// select事件触发后，自动触发updateInfo事件
    },

    clearForm() {
      if (this.activeName === 'first') {
        Object.assign(this.formOption.supplierform, this.$options.data.call(this).formOption.supplierform)
        this.stockLocationSearch()
      } else {
        Object.assign(this.formOption.supplierform, this.$options.data.call(this).formOption.supplierform, { isDetail: true })
        this.stockDetailSearch()
      }
    },
    stockLocationSearchForm() {
      this.pager.current = 1
      this._getVendorOffer(this.queryParameter(this.pager, this.formOption.supplierform))
    },
    stockDetailSearchForm() {
      this.detailPager.current = 1
      this._getVendorOfferDetail(this.queryParameter(this.detailPager, this.formDetailOption.supplierform))
    },
    stockLocationSearch(pager) {
      pager && Object.assign(this.pager, pager)
      this._getVendorOffer(this.queryParameter(this.pager, this.formOption.supplierform))
    },
    stockDetailSearch(detailPagers) {
      detailPagers && Object.assign(this.detailPager, detailPagers)
      this._getVendorOfferDetail(this.queryParameter(this.detailPager, this.formDetailOption.supplierform))
    },
    // 整单显示
    async _getVendorOffer(params) {
      try {
        this.supplierTableLoading = true
        const { datas } = await getVendorOffer(params)
        this.supplierTable = datas.records
        this.pager = datas.pager
      } finally {
        this.supplierTableLoading = false
      }
    },
    // 明细显示
    async _getVendorOfferDetail(params) {
      try {
        const isNotTemporary = this.formOption.supplierform.isNotTemporary
        this.supplierDetailTableLoading = true
        const { datas } = await getVendorOfferDetail(Object.assign({}, params, { isNotTemporary }))
        this.supplierDetailTable = datas.records
        this.detailPager = datas.pager
      } finally {
        this.supplierDetailTableLoading = false
      }
    },
    handelSupplier(row, disabled) {
      this.$router.push({
        path: 'added-quote',
        append: true,
        query: { 'id': row, 'disabled': disabled, isQury: true }
      })
    },
    handelSupplierDetail(row, disabled) {
      this.$router.push({
        path: 'added-quote',
        append: true,
        query: { 'id': row, 'disabled': disabled, isQury: true }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table__expanded-cell[class*="cell"] {
  padding: 20px 0;
}
.el-table--border th:nth-child(2).cell {
  .el-table__expanded-icon {
    display: none;
    .el-icon-arrow-right {
      display: none;
    }
  }
}
.expand-row {
  height: 44px;
  line-height: 44px;
  text-align: center;
  &.border {
    border-bottom: 1px solid #dfe6ec;
  }
}
.specifications-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.specifications {
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  // overflow-x: auto;
  // overflow-y: hidden;
  // &::-webkit-scrollbar {
  //   width: 3px;
  //   height: 5px;
  //   background-color: #D5D5D5
  // }

  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0,0,0,.1);
  //   border-radius: 10px;
  //   background-color: #F5F5F5
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  //   background-color: #D5D5D5
  // }
}
</style>
