<template>
  <el-form :ref="formOption.supplierformRef" :model="supplierform" label-width="100px" style="display: flex;">
    <div class="el-lt" style="width:85%;">
      <el-row :span="24" class="row-input">
        <el-col :span="8">
          <el-form-item label="报价单号" :class="$i18n.locale">
            <el-input v-model="supplierform.offerCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Style')" :class="$i18n.locale">
            <el-select
              v-model="supplierform.style"
              clearable
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              style="width:100%"
            >
              <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.styleName" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商" prop="vendorId" :class="$i18n.locale">
            <el-select v-model="supplierform.vendorId" clearable filterable :placeholder="$t('page.selectPlaceholder')">
              <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="币种" prop="currencyType" :class="$i18n.locale">
            <el-select v-model="supplierform.currencyType" clearable :placeholder="$t('page.selectPlaceholder')">
              <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" prop="offerStatus" :class="$i18n.locale">
            <el-select v-model="supplierform.offerStatus" clearable :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in _getAllCommodityDict('VENDOR_OFFER_STATUS')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报价人" :class="$i18n.locale">
            <el-select
              v-model="supplierform.createByName"
              clearable
              filterable
              :placeholder="$t('page.selectPlaceholder')"
            >
              <el-option v-for="item in employeeOptions" :key="item.id" :label="item.username" :value="item.username" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="4">
          <el-form-item label="报价时间" :class="$i18n.locale">
            <el-date-picker
              v-model="supplierform.joinDate"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :unlink-panels="true"
              :default-time="['00:00:00','23:59:59']"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24" class="mb-3">
        <el-col :span="24">
          <ImportButton :export-key="activeName === 'first' ? 'scm_vendor_offer' : 'scm_vendor_offer_detail'" :params="formParams" />

          <el-upload
            ref="uploadForm"
            action=""
            class="el-button padding-none"
            :http-request="uploadFile"
            :limit="1"
            :show-file-list="false"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="fileChange"
          >
            <el-button type="primary">{{ $t('page.import') }}</el-button>
          </el-upload>

          <el-button type="primary" @click="downTemplate">模板下载</el-button>
          <router-link to="added-quote" append>
            <el-button class="ml-2" type="primary">新增报价</el-button>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div class="el-rt" style="width:25%;">
      <el-col class="row-center">
        <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
        <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        <el-button type="text" @click="showRowClick()">{{ innerShowRow ? $t('page.hide') : $t('page.expand') }}
        </el-button>
      </el-col>
    </div>
    <div>
      <!-- 导入 -->
      <el-dialog
        title="导入"
        :visible.sync="importDialog"
        width="550px"
        :close-on-click-modal="false"
        :before-close="closeImportDialog"
      >
        <span v-if="fileList != ''" class="info-message">{{ $t('page.ConfirmImport') }} {{ fileList[0].name }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeImportDialog">取消</el-button>
          <el-button type="primary" @click="importDialogSubmit">导入详情</el-button>
        </span>
      </el-dialog>
      <!-- 导入详情 -->
      <el-dialog :visible.sync="uploadTableVisible" width="90%" title="导入详情" append-to-body :close-on-click-modal="false">
        <el-row>
          <el-col :span="4">
            <el-button type="primary" size="small" @click="handleDeletedetails()">删除</el-button>
          </el-col>
          <el-col :span="7">
            <el-form-item label="附件" prop="lfoafilelist">
              <el-upload
                ref=""
                :file-list="lfoafilelist"
                :headers="uploadHeaders"
                action=""
                :limit="5"
                class="upload-demo"
                show-file-list
                :http-request="handleUploadFile"
                :before-upload="beforeUpload"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :on-success="lfoahandleSuccess"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" :class="$i18n.locale">
              <el-input v-model="remark" :placeholder="$t('page.inputPlaceholder')" type="textarea" autosize />
            </el-form-item>
          </el-col>
        </el-row>

        <el-table
          ref="uploadTableDataRef"
          :data="importdata"
          :header-cell-style="{background:'#fafafa'}"
          tooltip-effect="dark"
          max-height="550px"
          :row-class-name="tableRowClassNameDetail"
          @selection-change="dialogSelectionChange"
        >
          <el-table-column type="selection" width="70" />
          <el-table-column prop="errorMsg" label="错误信息" width="120" align="center" sortable />
          <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="productDevelopCode" label="产品开发编号" width="140" align="center" sortable />
          <el-table-column prop="vendorName" label="供应商" width="140" align="center" sortable />
          <el-table-column prop="style" label="style" width="140" align="center" sortable />
          <el-table-column prop="color" label="color" width="140" align="center" sortable />
          <el-table-column prop="size" label="size" width="140" align="center" sortable />
          <el-table-column prop="isFlock" label="大底是否植绒" width="140" align="center" sortable />
          <el-table-column prop="packMethod" label="包装方式" width="140" align="center" sortable />
          <el-table-column prop="currencyType" label="币种" width="160" align="center" />
          <el-table-column prop="offerPrice" label="单价" width="120" align="center" sortable />
          <el-table-column prop="isExcludingTax" label="是否含税" width="120" align="center" sortable />
          <el-table-column prop="effectiveStartDate" label="生效开始日期" width="160" align="center" sortable />
          <el-table-column prop="effectiveEndDate" label="生效结束日期" width="160" align="center" sortable />
        </el-table>
        <el-row type="flex" justify="end" style="margin-top:20px;">
          <el-button @click="uploadTableVisible = false">取消</el-button>
          <el-button type="primary" :loading="Loading" @click="submitImportTable">提交</el-button>
        </el-row>
      </el-dialog>
    </div>
  </el-form>

</template>
<script>

import { downloadVendorQuote, usersList, queryVendorList, vendorOfferImport, vendorOfferImportView, uploadByPreUrlApi } from '@/api/scm-api'
import { queryStyleList } from '@/api/listSelection'
import axios from 'axios'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { handleDownload } from '@/utils'
import ImportButton from '@/components/ExportFile'

export default {
  name: 'SupplierForm',
  components: { ImportButton },
  mixins: [commodityInfoDict],
  props: {
    formOption: {
      type: Object,
      default() {
        return {
          supplierformRef: {
            type: String,
            default: 'supplierformRef'
          }
        }
      }
    },
    activeName: {
      type: String,
      default: () => ''
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const { showRow, supplierform } = this.formOption
    return {
      fileKeyList: [],
      firstCategoryList: [],
      secondCategoryList: [],
      thirdCategoryList: [],
      innerShowRow: showRow,
      supplierform: supplierform,
      storageareaList: [],
      siteGroupList: [],
      options: [],
      vendorOptions: [],
      styleOptions: '',
      colorOptions: '',
      sizeOptions: '',
      employeeOptions: [],
      fileList: [],
      importDialog: false,
      importdata: [],
      impordetDialog: false,
      uploadTableVisible: false,
      Loading: false,
      dialogselectlist: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },

      lfoafilelist: [],
      remark: ''
    }
  },
  computed: {
    // 获取输入框的值
    formParams() {
      const { joinDate = [] } = this.supplierform
      const [offerStartTime = '', offerEndTime = ''] = joinDate || []
      return Object.assign({}, this.supplierform, { offerStartTime, offerEndTime })
    }
  },
  watch: {
    'uploadTableVisible'(val) {
      if (!val) {
        this.remark = ''
        this.fileKeyList = []
        this.lfoafilelist = []
      }
    }
  },
  mounted() {
    this._queryVendorList()
    this._queryStyleList()
    this._usersList()
    if (this.activeName === 'second') {
      this.formOption.supplierform = {}
    }
  },
  methods: {

    stockHistoryExport() {
      this.$emit('stockHistoryExport')
    },

    // 选择style 带出color
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 报价人  取基础信息员工
    async _usersList() {
      const { datas } = await usersList()
      this.employeeOptions = datas
    },
    tableRowClassNameDetail({ row, rowIndex }) {
      if (row.errorMsg) {
        return 'all-row'
      }
      return ''
    },
    // 模板下载
    async downTemplate() {
      const { style } = this.supplierform
      const styleId = this.styleOptions?.find(i => i.styleName === style)?.id
      const arr = styleId ? [styleId] : []
      try {
        const data = await downloadVendorQuote(arr)

        if (Object.prototype.toString.call(data) === '[object ArrayBuffer]') {
          handleDownload(data, '供应商报价模板.xlsx')
        } else {
          this.$message.error('模板下载异常！')
        }
      } catch (err) {
        this.$message.error('模板下载异常！')
      }
    },

    showRowClick() {
      this.innerShowRow = !this.innerShowRow
    },
    queryClick() {
      this.$emit('stock-location-search')
    },
    resetClick() {
      this.$emit('clear-form')
    },
    async handleUploadFile(options) {
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })

      response && options.onSuccess(response) // 成功之后取值
      this.lfoafilelist.push(options.file)
    },
    // 导入
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      } else {
        return true
      }
    },
    lfoahandleSuccess(response) {
      response && this.fileKeyList.push(response?.objectName)
    },
    handleExceed() {
      this.$message.warning('当前限制选择 5 个文件')
    },
    handleRemove(val) {
      const index = this.lfoafilelist.findIndex((item) => item.uid === val.uid)
      this.lfoafilelist.splice(index, 1)
      this.fileKeyList.splice(index, 1)
    },
    // 导入
    async uploadFile() {
      const form = new FormData()
      form.append('file', this.file)
      const { datas } = await vendorOfferImportView(form)
      // console.log(datas)
      datas.map(v => {
        v.outPairs = v.unSalesPairs
      })
      this.importdata = datas
      this.uploadTableVisible = true
      // 隐藏弹窗清空数据
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    importDialogSubmit() {
      this.$refs.uploadForm.submit()
    },
    dialogSelectionChange(val) {
      let data = ''
      this.dialogselectlist = []
      for (let i = 0; i < val.length; i++) {
        data = val[i]
        this.dialogselectlist.push(data)
      }
    },
    handleDeletedetails() {
      const data = this.$refs.uploadTableDataRef.selection
      data.map(e => {
        var index = this.importdata.findIndex((i) => {
          return e.sku === i.sku
        })
        if (index !== -1) {
          this.importdata.splice(index, 1)
        }
      })
    },
    submitImportTable() {
      const data = {}
      if (this.dialogselectlist.length === 0) {
        this.$message({
          message: '请至少选择一条明细导入',
          type: 'warning'
        })
        return false
      }
      if (!this.lfoafilelist.length) {
        this.$confirm('附件必填，且至多选择五个', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (this.dialogselectlist.some(item => item.errorMsg)) {
        this.$confirm('列表中有错误信息不允许提交', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      data.list = this.dialogselectlist
      data.fileKeyList = this.fileKeyList
      data.remark = this.remark
      this._vendorOfferImport(data)
    },
    async _vendorOfferImport(data) {
      try {
        this.Loading = true
        const { code, msg } = await vendorOfferImport(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.Loading = false
          this.uploadTableVisible = false
        }
      } finally {
        this.uploadTableVisible = false
        this.Loading = false
      }
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table .all-row {
  color: red !important;
}
</style>
